<template>
  <v-card dense min-height="500" flat class="checkout">
    <v-card-text>
      <v-row align="start" dense>
        <v-col cols="12" md="8" sm="12">
          <v-sheet
            min-height="500"
            class="border-1 border-color--grey rounded-5 pa-3"
          >
            <template v-if="orders.length > 0">
              <v-row dense align="center" justify="space-between" class="mb-3">
                <v-checkbox
                  v-model="allSelected"
                  :indeterminate="selectAllIndeterminate"
                  :label="`Select All (${orders.length} items)`"
                  color="primary"
                  class="ml-5 primary--text fw-700"
                  off-icon="mdi-checkbox-blank-outline"
                  on-icon="mdi-checkbox-marked"
                  hide-details="auto"
                ></v-checkbox>
                <v-btn
                  depressed
                  :disabled="selected.length === 0"
                  :class="{ 'primary--text': selected.length }"
                  class="text-none fw-700 rounded-5"
                  color="white"
                  @click="confirmDeleteOrders"
                >
                  <v-icon left>mdi-delete</v-icon>
                  Delete Selected
                </v-btn>
              </v-row>
              <v-row
                dense
                align="center"
                justify="start"
                class="mb-3"
                v-for="(item, index) in orders"
                :key="index"
              >
                <v-col
                  md="12"
                  sm="12"
                  cols="12"
                  class="d-flex align-center justify-start"
                >
                  <v-checkbox
                    v-model="selected"
                    :value="item"
                    label=""
                    color="primary"
                    off-icon="mdi-checkbox-blank-outline"
                    on-icon="mdi-checkbox-marked"
                    hide-details="auto"
                  ></v-checkbox>
                  <v-sheet
                    class="d-flex align-start flex-column"
                    flat
                    max-width="90%"
                  >
                    <label
                      class="ml-2 my-1 cursor-pointer primary--text fw-700 fs-15"
                      @click="viewThisOrder(item)"
                    >
                      <span> {{ item.product.title | ucwords }} </span>
                      <span class="ml-3"> ({{ item.buy_price | money }}) </span>
                      <!-- <v-avatar size="30" class="float-right" title="From Dashcards">
                      <v-img  :lazy-src="settings.loader"  :lazy-src="settings.loader" :src="settings.squareLogo"></v-img>
                      </v-avatar> -->
                    </label>
                    <v-slide-group show-arrows center-active>
                      <v-slide-item
                        v-for="(slide, i) in item.orderattachments"
                        :key="i"
                      >
                        <v-card
                          flat
                          class="mx-2 d-flex align-center"
                          max-height="150"
                          min-height="150"
                          max-width="150"
                        >
                          <Attachment
                            :item="slide"
                            prefer-thumb
                            style="background: #e0e0e0; min-height: 150px"
                            :class="`ma-auto pa-2 border-0 rounded-0`"
                          ></Attachment>
                        </v-card>
                      </v-slide-item>
                    </v-slide-group>
                  </v-sheet>
                </v-col>
                <v-col md="12" v-if="next_page_url">
                  <v-btn
                    @click="getMoreOrders"
                    block
                    depressed
                    class="text-none my-2"
                    :loading="loading"
                  >
                    View More
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <Empty
              v-else
              icon="mdi-cart-off"
              :min-height="500"
              headline="Order cart is empty"
            ></Empty>
          </v-sheet>
        </v-col>
        <v-col cols="12" md="4" sm="12" class="d-flex flex-column">
          <v-sheet
            class="border-1 pa-3 border-color--grey rounded-5 d-flex flex-column"
          >
            <h2 class="mx-auto fs-17 mb-3 fw-700 primary--text">
              Order Details
            </h2>
            <v-list dense>
              <v-list-item>
                <v-list-item-title class="primary--text fs-17 fw-700">
                  Total items
                </v-list-item-title>
                <v-list-item-action-text class="primary--text fs-17 fw-700">
                  {{ selected.length }}
                </v-list-item-action-text>
              </v-list-item>
              <v-list-item>
                <v-list-item-title class="primary--text fs-17 fw-700">
                  Total
                </v-list-item-title>
                <v-list-item-action-text class="primary--text fs-17 fw-700">
                  {{ selected_total | money }}
                </v-list-item-action-text>
              </v-list-item>
            </v-list>
          </v-sheet>
          <v-btn
            block
            height="45"
            :disabled="loading || selected.length === 0"
            class="text-none fw-700 fs-16 my-3"
            color="primary"
            @click="handleCheckout"
          >
            Checkout
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <DeleteDialog
      v-model="confirm_action_dialog"
      title="Confirmation required!"
      text-content="Are you sure you want to delete these selected orders?"
      @delete="handleBulkDeleteOrders"
    ></DeleteDialog>
  </v-card>
</template>

<script>
import { offers_mixin } from '../mixin'
import _ from 'lodash'
import request from '@/services/axios_instance'
import OrderList from '../components/OrderList.vue'
import DeleteDialog from '@/common/DeleteDialog.vue'

export default {
  name: 'Checkout',
  mixins: [offers_mixin],
  components: {
    DeleteDialog,
    OrderList
  },
  data: () => ({
    allSelected: false,
    orders: [],
    selected: [],
    next_page_url: null,
    loading: false,
    active_order: null,
    confirm_action_dialog: false
  }),
  watch: {
    allSelected: {
      handler: function (val) {
        if (val) {
          this.selected = this.orders
        } else this.selected = []
      },
      immediate: true
    }
  },
  computed: {
    selected_total() {
      return _.sumBy(this.selected, (i) => {
        return i.buy_price * i.quantity
      })
    },
    selectAllIndeterminate() {
      return (
        this.selected.length !== this.orders.length && this.selected.length > 0
      )
    }
  },
  created() {
    this.getUnpaidOrders()
  },
  methods: {
    getUnpaidOrders() {
      this.loading = true
      request
        .get(`api/orders?paid=0`)
        .then(({ data }) => {
          this.orders = data.data
          this.next_page_url = data.next_page_url
        })
        .finally(() => (this.loading = false))
    },
    getMoreOrders() {
      this.loading = true
      if (this.next_page_url) {
        request
          .get(this.next_page_url)
          .then(({ data }) => {
            this.orders.push(...data.data)
            this.next_page_url = data.next_page_url
          })
          .finally(() => (this.loading = false))
      }
    },
    confirmDeleteOrders() {
      this.confirm_action_dialog = true
    },
    handleBulkDeleteOrders() {
      request
        .post(`api/orders/bulk-delete`, { ids: this.selected.map((i) => i.id) })
        .then(({ data }) => {
          this.getUnpaidOrders()
          this.selected = []
          this.appSnackbar(data.message)
        })
        .finally(() => {
          this.confirm_action_dialog = false
        })
    },
    handleCheckout() {
      this.$router
        .push({
          name: 'payment-to-orders',
          params: {
            ids: this.selected.map((i) => i.id).join('-')
          }
        })
        .catch(() => {})
    },
    viewThisOrder(order) {
      this.$router.push({
        name: 'view-order',
        params: { id: order.id },
        query: { redirect_uri: this.$route.fullPath }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.checkout-product {
  .toolbar {
    position: absolute;
    width: 100%;
    z-index: 5;
  }
}
</style>
